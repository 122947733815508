import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent
} from '@mui/material';
import axios from 'axios';
import InputMask from 'react-input-mask';

const LeadForm: React.FC = () => {

  const { partner } = useParams<{ partner?: string }>();

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    companyName: '',
    quantityEmployees: '1-5',
  });

  const [errors, setErrors] = useState<any>({
    phone: null,
    email: null,
  });

  const [loading, setLoading] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // if (name === 'phone' && value.length > 11) {
    //   return; // Não atualize o estado se exceder o limite
    // }
    // if (name === 'phone' && !/^[0-9]*$/.test(value)) {
    //   return; // Não atualize o estado se houver caracteres não numéricos
    // }
    setFormData({ ...formData, [name]: value.trimStart() });
  };

  const handleQuantityEmployeesChange = (e: SelectChangeEvent<string>) => {
    setFormData({ ...formData, quantityEmployees: e.target.value as string });
  };

  const handleSubmit = async (e: React.FormEvent) => {

    e.preventDefault();
    setLoading(true);


    // Validação
    const { name, phone, email, companyName, quantityEmployees } = formData;
    if (!name || !phone || !email || !companyName || !quantityEmployees) {
      setLoading(false)
      alert('Todos os campos são obrigatórios.');
      return;
    }


    // Validação de e-mail
    let errorEmail = null;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailPattern.test(email)) {
      errorEmail = 'E-mail inválido'
    }


    // Validação de phone
    let errorPhone = null;
    const phonePattern = /^\(\d{2}\) \d{5}-\d{4}$/
    if (!phonePattern.test(phone)) {
      errorPhone = 'Telefone inválido';
    }

    setErrors({
      email: errorEmail,
      phone: errorPhone,
    })
    if (errorEmail || errorPhone) {
      setLoading(false)
      return;
    }

    try {
      const options = {
        method: 'POST',
        url: 'https://us-central1-beneflex.cloudfunctions.net/createLead',
        headers: { 'Content-Type': 'application/json' },
        data: {
          email,
          name,
          phone,
          companyName,
          quantityEmployees,
          partner,
        }
      };


      await axios.request(options);
      alert('Enviado com sucesso');
    } catch (error) {
      console.log(error);
      alert('Falhar ao enviar');
    }
    setLoading(false)
  };
  return (
    <Container>
      <Typography variant="h4" align="center">
        {/* Formulário */}
        {partner}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nome"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>

            {/* <TextField
              fullWidth
              label="Telefone (Somente digitos)"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
              error={!!errors.phone}
              helperText={errors.phone}
            /> */}

            <div className="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root css-wb57ya-MuiFormControl-root-MuiTextField-root">
              <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeMedium MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiFormLabel-filled Mui-required MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeMedium MuiInputLabel-outlined css-1jy569b-MuiFormLabel-root-MuiInputLabel-root" data-shrink="false" htmlFor=":r3:" id=":r3:-label">
                Telefone *
              </label>
              <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl css-md26zr-MuiInputBase-root-MuiOutlinedInput-root">
                {/* <input aria-invalid="false" id=":r3:" name="email" required="" type="text" className="MuiInputBase-input MuiOutlinedInput-input css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input" value=""> */}
                <InputMask
                  mask="(99) 99999-9999"
                  className="MuiInputBase-input MuiOutlinedInput-input css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input"
                  value={formData.phone}
                  onChange={handleChange}
                  name="phone"
                  style={{ width: '100%' }}
                >
                </InputMask>

                <fieldset aria-hidden="true" className="MuiOutlinedInput-notchedOutline css-1d3z3hw-MuiOutlinedInput-notchedOutline">
                  <legend className="css-yjsfm1">
                    <span>Telefone*</span>
                  </legend>
                </fieldset>
              </div>
              {
                errors.phone && 
                <p style={{
                  color: 'red',/* Cor vermelha para a mensagem de erro */
                  fontSize: '14px',/* Tamanho da fonte */
                  marginTop: '4px',/* Espaço superior para separar a mensagem do campo */
                }}>
                  {errors.phone}
                </p>
              }
            </div>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              error={errors.email}
              helperText={errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nome da Empresa"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Quantidade de Colaboradores</InputLabel>
              <Select
                name="Quantidade de Colaboradores"
                value={formData.quantityEmployees}
                onChange={handleQuantityEmployeesChange}
                required
              >
                <MenuItem value="1-5">1 a 5</MenuItem>
                <MenuItem value="6-20">6 a 20</MenuItem>
                <MenuItem value="21-50">21 a 50</MenuItem>
                <MenuItem value="51+">Acima de 50</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
          style={{ marginTop: '20px' }}
        >
          Enviar
        </Button>
      </form>
    </Container>
  );
};

export default LeadForm;
